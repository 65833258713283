<template>
    <div>
      <button type="button" class="element-btn bg-gray" @click="dialogSmall = true">Dialog - Small</button>
      <button type="button" class="element-btn bg-gray" @click="dialogLarge = true">Dialog - Large</button>
      <v-dialog
          v-model="dialogSmall"
          content-class="module-dialog dialog-sm"
      >
        <div class="dialog-header">
          <p class="dialog-title">A6220</p>
        </div>
        <div class="dialog-body">
          <div class="dialog-content">
            <p class="title">MGC DISCONNECTED</p>
            <p class="text">
              소프트 스위치와 MEGACO 연동이 끊어진 경우 발생되는 경보입니다.<br>
              L3 장비간 광링크 상태를 점검 소프트 스위치와 연동되는 MEGACO IP와 L3 장비간 IP를 점검합니다.
            </p>
          </div>
        </div>
        <button type="button" class="btn-close" @click="dialogSmall = false"><span class="material-icons">close</span></button>
      </v-dialog>

      <v-dialog
          v-model="dialogLarge"
          content-class="module-dialog dialog-lg"
      >
        <div class="dialog-header">
          <p class="dialog-title">NMS 장비상세</p>
        </div>
        <div class="dialog-body">
          <div class="dialog-content">
            <table class="table-a">
              <colgroup>
                <col style="width:150px">
                <col style="width:calc((100% - 600px) / 4)">
                <col style="width:150px">
                <col style="width:calc((100% - 600px) / 4)">
                <col style="width:150px">
                <col style="width:calc((100% - 600px) / 4)">
                <col style="width:150px">
                <col style="width:calc((100% - 600px) / 4)">
              </colgroup>
              <tbody>
                <tr>
                  <th scope="row">EquipOpCode</th>
                  <td><span class="value">변경</span></td>
                  <th scope="row">Equipment ID</th>
                  <td><span class="value">CBCJX1858</span></td>
                  <th scope="row">Managed IP</th>
                  <td><span class="value">10.8.194.21</span></td>
                  <th scope="row">Headquarter ID</th>
                  <td><span class="value">468994</span></td>
                </tr>
                <tr>
                  <th scope="row">HeadquarterName</th>
                  <td><span class="value">충북충남NW운용본부</span></td>
                  <th scope="row">NCS ID</th>
                  <td><span class="value">469032</span></td>
                  <th scope="row">NCS Name</th>
                  <td><span class="value">충북엑세스 운용센터</span></td>
                  <th scope="row">OMC ID</th>
                  <td><span class="value">469033</span></td>
                </tr>
                <tr>
                  <th scope="row">OMC Name</th>
                  <td><span class="value">품질계획부</span></td>
                  <th scope="row">Office ID</th>
                  <td><span class="value">R04000</span></td>
                  <th scope="row">OfficeName</th>
                  <td><span class="value">남청주 국사</span></td>
                  <th scope="row">RSSOffice ID</th>
                  <td><span class="value">R04000</span></td>
                </tr>
                <tr>
                  <th scope="row">RSSOffice Name</th>
                  <td><span class="value">남청주 국사</span></td>
                  <th scope="row">Equipment Name</th>
                  <td><span class="value">남청주-남청주-BAGW279</span></td>
                  <th scope="row">EMS IP</th>
                  <td><span class="value">10.240.4.89</span></td>
                  <th scope="row">EquipUsageCode</th>
                  <td><span class="value">1101</span></td>
                </tr>
                <tr>
                  <th scope="row">ClassTypeCode</th>
                  <td><span class="value">1</span></td>
                  <th scope="row">VendorCode</th>
                  <td><span class="value">DWT</span></td>
                  <th scope="row">ModelName</th>
                  <td><span class="value">MUR_TDXAGW-3형</span></td>
                  <th scope="row">ModelNameSCode</th>
                  <td><span class="value">E47265</span></td>
                </tr>
                <tr>
                  <th scope="row">InstallLocation</th>
                  <td><span class="value">충북 청주시 상당구 방서동 206동 7호</span></td>
                  <th scope="row">Address</th>
                  <td><span class="value">충청북도</span></td>
                  <th scope="row">Degree</th>
                  <td><span class="value">1101</span></td>
                  <th scope="row">EquipmentName2</th>
                  <td><span class="value">남청주-남청주-BAGW279</span></td>
                </tr>
                <tr>
                  <th scope="row">MainClsType</th>
                  <td><span class="value">11</span></td>
                  <th scope="row">subClsType</th>
                  <td><span class="value">10</span></td>
                  <th scope="row">Slln</th>
                  <td><span class="value">0</span></td>
                  <th scope="row">IpsecgwIP</th>
                  <td><span class="value">175.228.237.1</span></td>
                </tr>
                <tr>
                  <th scope="row">MAC</th>
                  <td></td>
                  <th scope="row">MGID</th>
                  <td><span class="value">0279</span></td>
                  <th scope="row">Write_Time</th>
                  <td><span class="value">2021-11-15 11:00:00.76</span></td>
                  <th scope="row">적용상태</th>
                  <td><span class="value">미적용</span></td>
                </tr>
                <tr>
                  <th scope="row">적용일</th>
                  <td>2021-11-15 11:00:00.76</td>
                  <th scope="row">적용자</th>
                  <td><span class="value">DKB</span></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="dialog-footer">
          <button type="button" class="element-btn bg-gray" @click="dialogLarge = false">적용취소</button>
          <button type="button" class="element-btn bg-red" @click="dialogLarge = false">적용완료</button>
        </div>
        <button type="button" class="btn-close" @click="dialogLarge = false"><span class="material-icons">close</span></button>
      </v-dialog>
    </div>
</template>
<script>
export default {
  data () {
    return {
      dialogSmall: false,
      dialogLarge: false
    }
  }
}
</script>
